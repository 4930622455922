var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Image")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Notes")]),_c('th',[_vm._v("Category")])])]),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll}},_vm._l((_vm.list),function(recipe){return _c('tr',{key:recipe.id,staticClass:"clickable new-tab-link-row"},[_c('td',[_c('cloudinary-image',{attrs:{"image":recipe.cloudinary_image,"tile":true,"aspect-ratio":"1.7","alt":"Food Recipe","height":"70","size":"70"}}),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.recipe',
              params: { id: recipe.id },
            }}})],1),_c('td',[_vm._v(" "+_vm._s(recipe.title)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.recipe',
              params: { id: recipe.id },
            }}})],1),_c('td',{attrs:{"width":"60%"}},[_vm._v(" "+_vm._s(recipe.description)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.recipe',
              params: { id: recipe.id },
            }}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("readableEnums")(recipe.categories,_vm.categories))+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.recipe',
              params: { id: recipe.id },
            }}})],1)])}),0),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"4","row":""}}):_vm._e()],1),(!_vm.list.length && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Videos"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }