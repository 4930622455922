<template>
  <v-row class="bbr-list-drafts">
    <v-col v-if="hasListData || isSearching" cols="12">
      <div class="form-container d-flex align-center">
        <div class="search-form">
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            label="Search food entries"
            style="width:400px"
            v-model="searchKey"
            @input="searchChange"
            autocomplete="off"
          />
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <recipe-list-table
        v-if="type == 1"
        :is-searching="isSearching"
        :list="list"
        :loading="loading"
        :meta="listMeta"
        list-for="Active"
        @loadMore="loadMoreContent"
        @goTo="goTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import RecipeListTable from '../components/RecipeListTable'
import { mapMutations, mapState, mapActions } from 'vuex'
import Event from '@/services/eventBus'
import debounce from 'lodash/debounce'

export default {
  name: 'DraftFoods',

  components: {
    RecipeListTable,
  },

  data() {
    return {
      loading: false,
      searchKey: null,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.recipe.filter,
      list: (state) => state.recipe.drafts.list,
      listMeta: (state) => state.recipe.drafts.listMeta,
    }),

    hasListData() {
      return !!this.list.length
    },

    isSearching() {
      return !!this.searchKey
    },

    type() {
      return this.$route.meta ? this.$route.meta.type : 2
    },
  },

  created() {
    this.setFilter({ search: null, completed: false })
    this.clearList()

    if (!this.filter.completed) {
      this.searchKey = this.filter.search
    }

    if (this.searchKey) {
      this.fetchListData()
    }

    Event.$on('update-draft-recipe-list', () => {
      this.setFilter({ completed: false })
      this.clearList()
      this.fetchListData()
    })
  },

  methods: {
    ...mapActions({
      getDataList: 'recipe/getDataList',
    }),

    ...mapMutations({
      setFilter: 'recipe/setFilter',
      clearList: 'recipe/clearList',
    }),

    searchChange(key) {
      this.setFilter({ search: key, completed: false })
      this.searchData()
    },

    searchData: debounce(function() {
      this.clearList()
      this.fetchListData()
    }, 550),

    async fetchListData(page = 1) {
      if (this.loading) return

      let search = this.searchKey
      let completed = 0
      // type: 1 Recipe, 2 Food
      let params = {
        page,
        type: this.type,
        search,
        completed,
        status: this.$route.meta.status,
      }

      this.loading = true

      await this.getDataList(params)

      this.loading = false
    },

    loadMoreContent() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListData(this.listMeta.current_page + 1)
      }
    },

    goTo(id) {
      this.$router.push({ name: 'form.recipe', params: { id } })
    },
  },
}
</script>
